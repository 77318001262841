var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('el-row',{staticClass:"course-menu m_tb_20"},[_c('el-col',{staticStyle:{"border-radius":"0.5rem","overflow":"hidden","margin-left":"20px","position":"absolute","right":"0"},attrs:{"span":24}},[_c('el-carousel',{attrs:{"trigger":"hover","height":"369px"}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{key:index,staticStyle:{"background":"lightgray"}},[_c('router-link',{attrs:{"to":{ path: '/courseDetail', query: { courseId: item.id } }}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"width":"100%","height":"100%","src":item.coverUrl,"alt":"banner"}})])],1)}),1)],1)],1),_c('LiveCourseList',{attrs:{"courseListData":_vm.liveCourseData}}),_c('CourseList',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.recommendCourseData.courseList && _vm.formerCourseData.courseList.length > 0
    ),expression:"\n      recommendCourseData.courseList && formerCourseData.courseList.length > 0\n    "}],attrs:{"courseListData":_vm.recommendCourseData}}),_c('CourseList',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.formerCourseData.courseList && _vm.formerCourseData.courseList.length > 0
    ),expression:"\n      formerCourseData.courseList && formerCourseData.courseList.length > 0\n    "}],attrs:{"courseListData":_vm.formerCourseData}}),_c('CourseList',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.yi0yiCourseList.courseList && _vm.yi0yiCourseList.courseList.length > 0
    ),expression:"\n      yi0yiCourseList.courseList && yi0yiCourseList.courseList.length > 0\n    "}],attrs:{"courseListData":_vm.yi0yiCourseList}}),(_vm.userInfo && _vm.userInfo.role_name !== 'student')?_c('img',{staticClass:"to-live-btn pointer",attrs:{"src":_vm.toLiveImg,"alt":"立即直播"},on:{"click":_vm.toMine}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }