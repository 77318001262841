<template>
  <div class="home">
    <!--    课程菜单-->
    <el-row class="course-menu m_tb_20">
      <!--   目录   -->
      <!-- <el-col :span="7.5">
        <CourseMenu :menuData="courseMenuData"/>
      </el-col> -->
      <el-col
        :span="24"
        style="
          border-radius: 0.5rem;
          overflow: hidden;
          margin-left: 20px;
          position: absolute;
          right: 0;
        ">
        <el-carousel
          trigger="hover"
          height="369px">
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="index"
            style="background: lightgray">
            <router-link
              :to="{ path: '/courseDetail', query: { courseId: item.id } }">
              <img
                width="100%"
                height="100%"
                :src="item.coverUrl"
                style="object-fit: cover"
                alt="banner" />
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <!--    直播课程-->
    <!--        <CourseList :courseListData="liveCourseData" style="width: 100%;"/>-->
    <LiveCourseList :courseListData="liveCourseData" />
    <!--    推荐课程-->
    <CourseList
      :courseListData="recommendCourseData"
      v-show="
        recommendCourseData.courseList && formerCourseData.courseList.length > 0
      " />
    <!--    往期精彩课程-->
    <CourseList
      :courseListData="formerCourseData"
      v-show="
        formerCourseData.courseList && formerCourseData.courseList.length > 0
      " />
    <!--  101 视频  -->
    <CourseList
      :courseListData="yi0yiCourseList"
      v-show="
        yi0yiCourseList.courseList && yi0yiCourseList.courseList.length > 0
      " />
    <!--  立即直播按钮  -->
    <img
      v-if="userInfo && userInfo.role_name !== 'student'"
      class="to-live-btn pointer"
      :src="toLiveImg"
      alt="立即直播"
      @click="toMine" />
  </div>
</template>

<script>
import defaultImg from '@/assets/image/default.png';
import toLiveImg from '@/assets/img/Group 733.png';
import CourseList from '@/components/index/CourseList';
import LiveCourseList from '@/components/live/LiveCourseList';
import {
  getCourseList,
  getResourcesClassify,
  getResourcesContents,
} from '@/api/index';
import xxx from '@/utils/data';
import { sessionGet } from '../../utils/local';
import { getResourcesCourse } from '../../api/search';

export default {
  name: 'index',
  components: {
    CourseList,
    LiveCourseList,
  },
  data: () => {
    return {
      searchKeyword: '',
      bannerList: [],
      courseMenuData: [],
      liveCourseData: {
        courseList: [{ id: '' }, { id: '' }, { id: '' }],
      },
      recommendCourseData: {
        courseList: [],
      },
      formerCourseData: {
        courseList: [],
      },
      defaultImg: defaultImg,
      toLiveImg,
      userInfo: sessionGet('userInfo'),
      yi0yiCourseList: {},
    };
  },
  async created() {
    await this.getMenu();
    await this.getCourseList();
    await this.getClassify();
    // await this.getResourcesContents()
    // await this.getMoreCatalogue()
  },
  methods: {
    async getCourseList() {
      let params = {
        current: 1,
        size: 4,
        isTelecast: 4,
        name: '',
      };

      await getCourseList(params).then((res) => {
        this.liveCourseData = this.processingData(
          res.data.telecastCourse.records,
          'live'
        );
        this.recommendCourseData = this.processingData(
          res.data.resourcesDataIPage.records,
          'recommend'
        );
        this.formerCourseData = this.processingData(
          res.data.luBoData.records,
          'former'
        );
        this.bannerList = this.formerCourseData.courseList;
      });
    },
    getMenu() {
      getResourcesClassify({
        current: 1,
        size: 100,
      }).then((res) => {
        this.courseMenuData = res.data.records;
      });
    },
    // 获取中小学目录
    getResourcesContents() {
      getResourcesContents({
        calssifyId: '1518399756408508417',
        type: 1,
      }).then((res) => {
        xxx.xxx(res.data, 0);
      });
    },
    // 获取更多中小学目录
    getMoreCatalogue() {
      getResourcesContents({
        calssifyId: '1518399756408508417',
        type: 2,
      }).then((res) => {
        xxx.yyy(res.data, 0);
      });
    },
    // 处理数据
    processingData(data, type) {
      let courseType = type,
        courseTitle;

      if (!data) {
        return;
      }
      data.forEach((item) => {
        if (item.introduce && type !== 'live') {
          item.teacher = item.remarks1;
          item.teacherInfo = item.introduce;
        } else if (type === 'live') {
          if (item.beginState === '0') {
            item.stateVal = 2;
          } else if (item.beginState === '2') {
            item.stateVal = 1;
          }
          item.teacher = item.teacherName;
          item.teacherInfo = '';
        }
      });

      switch (type) {
        case 'live':
          courseTitle = '直播课';
          break;
        case 'recommend':
          courseTitle = '热度推荐';
          break;
        case 'former':
          courseTitle = '往期精彩课程';
          break;
      }
      return {
        courseTitle,
        courseType,
        courseList: data,
      };
    },
    getClassify() {
      getResourcesContents({
        calssifyId: '1554300188481306626',
        type: 2,
      }).then((res) => {
        res.data.forEach((item) => {
          if (item.name === '101视频') {
            this.get101List(item.id);
          }
        });
      });
    },
    // 获取101视频
    get101List(id) {
      getResourcesCourse({
        isTelecast: 2,
        contentsId: id.toString(),
        userId: '',
        name: '',
        current: 1,
        size: 12,
        classifyId: '',
      }).then((res) => {
        this.yi0yiCourseList = {
          courseTitle: '101视频',
          courseList: res.data.records.slice(0, 4),
        };
      });
    },
    toMine() {
      this.$router.push('/mineTeacher/course');
    },
  },
};
</script>
<style scoped>
.home {
  width: 120rem;
  margin: 1rem auto;
  position: relative;
}

.course-menu {
  height: 369px;
  position: relative;
}

.to-live-btn {
  width: 156px;
  height: 130px;
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 999;
}
</style>
