import {sessionSet} from "@/utils/local";

let selectMenu = []
let calssifyIdItem = []
let resourceCatalogue = []

let moreSelectMenu = []
let moreCalssifyIdItem = []

function aaa(data, i) {
    resourceCatalogue = []
    if (data.length === 0) {
        return
    }
    let arr = []
    data.map(item => {
        if (item.children.length !== 0) {
            arr.push(item)
            if (data[0].id === item.id) {
                calssifyIdItem[i] = item.id
            }
        } else {
            let obj = {name: item.name, id: item.id}
            resourceCatalogue.push(obj)
        }
    })
    selectMenu[i] = arr
    i++
    if (data[0].children.length !== 0) {
        aaa(data[0].children, i)
    }
}

function bbb(data, i) {
    if (data.length === 0) {
        return
    }
    let arr = []
    data.map(item => {
        // if (item.children.length !== 0) {
        arr.push(item)
        if (data[0].id === item.id) {
            moreCalssifyIdItem[i] = item.id
        }
        // }
    })
    moreSelectMenu[i] = arr
    i++
    if (data[0].children.length !== 0) {
        bbb(data[0].children, i)
    }
}

async function xxx(data, i) {
    await aaa(data, i)

    let catalogue = {
        selectMenu,
        calssifyIdItem,
        resourceCatalogue
    }

    sessionSet('catalogue', catalogue)
}

async function yyy(data, i) {
    await bbb(data, i)
    let moreCatalogue = {
        moreSelectMenu,
        moreCalssifyIdItem
    }
    sessionSet('moreCatalogue', moreCatalogue)
}


export default {
    xxx,
    yyy
}
