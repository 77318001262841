// 引入封装的第一层axios接口
import request from '@/utils/req.js'

// 获取数据
export const getCourseList = (params) => {
    return request({
        url: '/api/blade-resources/data/getResourcesDate',
        method: 'get',
        params: {
            ...params
        }
    })
}

//资源目录分类
export const getResourcesClassify = (params) => {
    return request({
        url: '/api/blade-resources/classify/getResourcesClassifyPage',
        method: 'get',
        params: {
            ...params
        }
    })
}

//获取资源目录下目录
export const getResourcesContents = (params) => {
    return request({
        url: '/api/blade-resources/contents/getResourcesContents',
        method: 'get',
        params: {
            ...params
        }
    })
}


//获取中小学目录
export const getContents = (params) => {
    return request({
        url: '/api/blade-resources/contents/getContents',
        method: 'get',
        params: {
            ...params
        }
    })
}
