<template>
  <div class="box flex_row">
    <div style="width: 69%">
      <div class="flex_row title-box p_tb_10">
        <div
          style="
            position: absolute;
            width: 5px;
            height: 20px;
            background-color: #3b8989;
            border-radius: 2px;
            top: 50%;
            transform: translateY(-12px);
          "></div>
        <span
          class="fs_20 fw_bold m_lr_10"
          style="color: #0f4444">
          直播课
        </span>
        <router-link :to="{ path: '/liveSearch', query: { type: 'more' } }">
          <span
            class="m_t_15"
            style="color: #0f4444; opacity: 0.6">
            更多
            <i class="icon-font el-icon-caret-right"></i>
          </span>
        </router-link>
      </div>
      <div
        class="flex_row"
        v-if="courseListData.courseList.length !== 0">
        <div
          class="left courseBox br_10 bg_fff"
          @click="drump(courseListData.courseList[0])"
          style="cursor: pointer">
          <!-- query:{courseId:courseListData.courseList[0].id -->
          <div class="imgBox">
            <img
              class="img"
              :src="courseListData.courseList[0].coverUrl"
              alt="" />
            <div
              class="tag c_fff p_lr_10 fs_12"
              :class="{
                'living': courseListData.courseList[0].beginState == 0,
              }">
              {{ stateJSON[courseListData.courseList[0].beginState] }}
            </div>
          </div>
          <div class="p_in_10">
            <div
              class="fs_16 fw_bold"
              style="color: #0f4444">
              {{ courseListData.courseList[0].name }}
            </div>
            <div
              class="m_tb_5 fs_14 c_8c"
              style="color: #0f4444; opacity: 0.6">
              {{ courseListData.courseList[0].teacherName }}
            </div>
            <div
              class="fs_12 c_bf"
              style="color: #0f4444; opacity: 0.4">
              时间：{{ courseListData.courseList[0].courseStartTime }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          position: relative;
          text-align: center;
          background: #fff;
          height: 430px;
          border-radius: 10px;
        ">
        <div style="position: relative">
          <img
            src="../../assets/img/Group 1624@2x.png"
            style="width: 300px; margin-top: 5%" />
          <div
            style="
              position: absolute;
              bottom: -40px;
              width: 100%;
              font-size: 16px;
              color: #0f4444;
            ">
            暂无直播课，敬请关注...
          </div>
        </div>
      </div>
    </div>
    <div style="width: 29%">
      <div class="flex_row title-box p_tb_10">
        <div
          style="
            position: absolute;
            width: 5px;
            height: 20px;
            background-color: #3b8989;
            border-radius: 2px;
            top: 50%;
            transform: translateY(-12px);
          "></div>
        <span
          class="fs_20 fw_bold m_lr_10"
          style="color: #0f4444">
          录播课堂
        </span>
        <router-link :to="{ path: '/#' }">
          <span
            class="m_t_15"
            style="color: #0f4444; opacity: 0.6">
            更多
            <i class="icon-font el-icon-caret-right"></i>
          </span>
        </router-link>
      </div>
      <div
        class="bg_fff luboketang br_10"
        style="text-align: center; position: relative">
        <img
          src="../../assets/img/Group 589@2x.png"
          width="100%"
          alt="" />
        <img
          src="../../assets/img/Group 1624@2x.png"
          width="58%"
          style="position: absolute; left: 50%; margin-left: -29%; top: 22%" />
        <p
          class="fs_16"
          style="
            text-align: center;
            font-size: 16px;
            margin-top: -35px;
            color: #0f4444;
          ">
          录播课堂开发中，敬请期待...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import LiveCourse from '@/components/live/LiveCourse'
import img from '@/assets/image/5g.jpg';
import img1 from '@/assets/image/gummy-programming.png';
import { sessionGet, sessionSet } from '@/utils/local';

export default {
  name: 'LiveCourseList',
  components: {
    // LiveCourse
  },
  props: {
    courseListData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      img,
      img1,
      stateJSON: {
        2: '未开始',
        0: '直播中',
      },
    };
  },
  methods: {
    drump(item) {
      sessionSet('details', item);
      let userInfo = sessionGet('userInfo');
      if (!userInfo) {
        this.$router.push('/login');
      } else {
        // 不需要报名：1需要报名 0不需要
        if (item.isSubscribe == 0) {
          // 直播中
          if (item.beginState == 0) {
            // 直播：1直播 0录播
            if (item.isLubo == 1) {
              // 是否是自己的直播课
              if (item.updateUserId === userInfo.user_id) {
                this.$router.push('/order?courseId=' + item.id);
              } else {
                this.$router.push('/liveStu/player?telecastId=' + item.id);
              }
              // 录播
            } else {
              this.$router.push('/liveStu/playerLubo?telecastId=' + item.id);
            }
            // 未开播
          } else {
            this.$router.push('/order?courseId=' + item.id);
          }
          // 需要报名
        } else {
          // 直播中
          if (item.beginState == 0) {
            // 是否已报名：>0已报名 <=0未报名
            if (item.subscribeId > 0) {
              // 直播
              if (item.isLubo == 1) {
                this.$router.push('/liveStu/player?telecastId=' + item.id);
                // 录播
              } else {
                this.$router.push('/liveStu/playerLubo?telecastId=' + item.id);
              }
              // 未报名
            } else {
              this.$router.push('/order?courseId=' + item.id);
            }
          } else {
            this.$router.push('/order?courseId=' + item.id);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.box {
  justify-content: space-between;
}

.courseBox {
  width: 100%;
  height: 430px;
  overflow: hidden;
  transition: all 0.5s;
}

.courseBox:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 16px;
  transition: all 0.3s;
}

.imgBox {
  position: relative;
}

.img {
  width: 100%;
  height: 340px;
  object-fit: cover;
}

.tag {
  position: absolute;
  top: 0;
  background-color: #4390f6;
  right: 0;
  border-radius: 0 0 0 10px;
  line-height: 26px;
}

.title-box {
  justify-content: space-between;
  position: relative;
  height: 51px;
}

.luboketang {
  height: calc(100% - 51px);
}

.right-course {
  justify-content: space-between;
}

.living {
  background-color: #eb4251;
}
</style>
