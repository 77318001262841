// 引入封装的第一层axios接口
import request from '@/utils/req.js'


export const getResourcesCourse = (params) => {
    return request({
        url: '/api/blade-resources/data/getResourcesDataPage',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const searchCourseList = (params) => {
    return request({
        url: '/api/blade-resources/data/getResourcesDate',
        method: 'get',
        params: {
            ...params
        }
    })
}
