<template>
  <div class="box m_tb_20">
    <!--    标题-->
    <div
      class="flex_row title-box p_tb_10"
      style="position: relative; height: 51px">
      <div
        style="
          position: absolute;
          width: 5px;
          height: 20px;
          background-color: #3b8989;
          border-radius: 2px;
          top: 50%;
          transform: translateY(-12px);
        "></div>
      <span
        class="fs_20 fw_bold m_lr_10"
        style="color: #0f4444">
        {{ courseListData.courseTitle }}
      </span>
      <router-link
        :to="{
          path: courseListData.courseType == 'live' ? '/liveSearch' : '/search',
          query: { type: 'more' },
        }">
        <span
          class="m_t_15"
          v-if="!courseListData.since"
          style="color: #0f4444; opacity: 0.6">
          更多
          <i class="icon-font el-icon-caret-right"></i>
        </span>
      </router-link>
    </div>
    <!--    内容-->
    <el-row class="flex_row">
      <!--直播课页面单独处理-->
      <!--      <div v-if="courseListData.courseType=='live'" style="display: flex">
              <div style="display: flex;">
                <div class="course-box bg_fff br_8 of_h" style="width: 53%;height: auto;">
                  <div class="pointer" @click="drump(courseListData.courseType,courseListData.courseList[0])">
                    &lt;!&ndash;:src="courseListData.courseList[0].coverUrl"&ndash;&gt;
                    <img
                      src="https://r1-ndr.ykt.cbern.com.cn/edu_product/65/video/30e7115253f111eb96b8fa20200c3759/cover/1.jpg"
                      style="object-fit: cover;" width="100%" height="160" alt="课程" />
                    <div class="p_in_10">
                      <div class="fs_14 m_b_10" v-if="courseListData.courseType === 'live'">
                        {{ courseListData.courseList[0].courseStartTime }}
                      </div>
                      <div class="fs_16 m_b_10 text_hidden" style="height:22px;">
                        {{ courseListData.courseList[0].name }}
                      </div>
                      <div class="fs_14 c_666 flex_row" style="line-height: 20px;">
                        <span>{{ courseListData.courseList[0].teacher }}</span>
                        <el-tag size="small" type="danger" class="live_tag" v-if="courseListData.courseList[0].stateVal">
                          {{ stateJSON[courseListData.courseList[0].stateVal] }}
                        </el-tag>
                        <span class="live_tag flex_row" v-else>
                          <img class="m_lr_10" width="20px" :src="playImg" alt="播放量">
                          <span>
                            {{ courseListData.courseList[0].browseNumber > 10000 ? (courseListData.courseList[0].browseNumber / 10000).toFixed(1) + ' 万' : courseListData.courseList[0].browseNumber }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div v-for="(item, index) of courseListData.courseList" :key="index">
                    <div class="course-box bg_fff br_8 of_h" v-if="index>0&&index<3">
                      <div class="pointer" @click="drump(courseListData.courseType,item)">
                        &lt;!&ndash;item.coverUrl&ndash;&gt;
                        <img
                          src="https://r1-ndr.ykt.cbern.com.cn/edu_product/65/video/30e7115253f111eb96b8fa20200c3759/cover/1.jpg"
                          style="object-fit: cover;" width="100%" height="160" alt="课程" />
                        <div class="p_in_10">
                          <div class="fs_14 m_b_10" v-if="courseListData.courseType === 'live'">
                            {{ item.courseStartTime }}
                          </div>
                          <div class="fs_16 m_b_10 text_hidden" style="height:22px;">
                            {{ item.name }}
                          </div>
                          <div class="fs_14 c_666 flex_row" style="line-height: 20px;">
                            <span>{{ item.teacher }}</span>
                            <el-tag size="small" type="danger" class="live_tag" v-if="item.stateVal">
                              {{ stateJSON[item.stateVal] }}
                            </el-tag>
                            <span class="live_tag flex_row" v-else>
                              <img class="m_lr_10" width="20px" :src="playImg" alt="播放量">
                              <span>
                                {{ item.browseNumber > 10000 ? (item.browseNumber / 10000).toFixed(1) + ' 万' : item.browseNumber }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div style="width: 100%;">
                <img src="../../assets/image/developing.png" width="100%"/>
              </div>
            </div>-->
      <div
        class="course-box bg_fff br_8 of_h"
        v-for="(item, index) of courseListData.courseList"
        :key="index">
        <div
          class="pointer"
          @click="drump(courseListData.courseType, item)">
          <img
            :src="item.coverUrl"
            style="object-fit: cover; position: relative"
            width="100%"
            height="160"
            alt="课程" />
          <div
            class="p_in_10"
            style="height: 72px">
            <div
              class="fs_14 m_b_10"
              v-if="courseListData.courseType === 'live'">
              {{ item.courseStartTime }}
            </div>
            <div
              class="fs_16 m_b_10 text_hidden"
              style="height: 22px; color: #0f4444">
              {{ item.name }}
            </div>
            <div
              class="fs_14 c_666 flex_row"
              style="line-height: 20px">
              <span style="color: #0f4444; opacity: 0.6">
                {{ item.teacher }}
              </span>
              <el-tag
                size="small"
                type="danger"
                class="live_tag"
                v-if="item.stateVal">
                {{ stateJSON[item.stateVal] }}
              </el-tag>
              <span
                class="live_tag flex_row"
                style="
                  position: absolute;
                  top: 134px;
                  background: rgba(19, 69, 68, 0.4);
                  color: #fff;
                  right: 0;
                  display: flex;
                  align-items: center;
                  padding: 3px 5px 3px 0;
                  border-radius: 10px 0px 0px 0px;
                "
                v-else>
                <img
                  width="15px"
                  height="15px"
                  :src="playImg"
                  style="border-radius: 100%; margin: 0 5px 0 8px"
                  alt="播放量" />
                <span style="color: #c5cbcb">
                  {{
                    item.browseNumber > 10000
                      ? (item.browseNumber / 10000).toFixed(1) + ' 万次'
                      : item.browseNumber + '次'
                  }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>
<script>
import defaultImg from '@/assets/image/default.png';
import playImg from '@/assets/image/play.png';
import { sessionGet, sessionSet } from '@/utils/local';

export default {
  name: 'CourseList',
  props: {
    courseListData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      defaultImg,
      playImg,
      /*
       * stateVal
       *   0 未通过
       *   1 未开始
       *   2 直播中
       *   3 已结束
       *   4 审核中
       * */
      stateJSON: {
        0: '未通过',
        1: '未开始',
        2: '直播中',
        3: '已结束',
        4: '审核中',
      },
    };
  },
  methods: {
    drump(type, item) {
      let userInfo = sessionGet('userInfo');
      if (type == 'live') {
        if (userInfo && item.subscribeId > 0 && item.beginState == 0) {
          sessionSet('details', item);
          this.$router.push('/liveStu/player?telecastId=' + item.id);
        } else {
          this.$router.push('/order?courseId=' + item.id);
        }
      } else {
        this.$router.push('/courseDetail?courseId=' + item.id);
      }
    },
  },
};
</script>
<style scoped>
.box {
  width: 1200px;
}

.title-box {
  justify-content: space-between;
}

.course-box {
  width: 285px;
  margin-right: 20px;
  position: relative;
  transition: all 0.5s;
}

.course-box:last-child {
  margin: 0;
}

.course-box:hover {
  box-shadow: 0 0 10px 2px #e0e0e0;
  transform: translateY(-8px);
  transition: all 0.5s;
}

.tag {
  position: absolute;
  top: 0;
  left: 0;
}

.live_tag {
  position: absolute;
  right: 10px;
}
</style>
